import { createApp } from 'vue';
import App from './App.vue';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import router from './router';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

createApp(App).use(router).mount('#app');
