<template>
  <div>
    <form @submit.prevent="generateRecipe">
      <div
        v-for="(ingredient, index) in ingredients"
        :key="index"
        class="ingredient-input"
      >
        <label :for="'ingredient-' + index" class="ingredient-label">
          Ingredient {{ index + 1 }}:
        </label>
        <input
          type="text"
          :id="'ingredient-' + index"
          v-model="ingredients[index]"
          class="ingredient"
        />
      </div>
      <button class="add-button" @click.prevent="addIngredient">
        + Add Ingredient
      </button>
      <label for="calorie-limit" class="calorie-limit-label">
        Calorie Limit:
      </label>
      <input type="number" id="calorie-limit" v-model="calorieLimit" min="50" />
      <button type="submit">Generate Recipe</button>
    </form>
    <div class="loading-container" v-if="loading">
      <div class="circle"></div>
    </div>
    <div v-if="recipeData" class="recipe-container">
      <h2>{{ recipeData.recipeTitle }}</h2>
      <h4>Recipe Source: {{ recipeData.recipeSource }}</h4>
      <h3>Ingredients:</h3>
      <ul>
        <li v-for="(ingredient, index) in recipeData.ingredients" :key="index">
          {{ ingredient.amount }} {{ ingredient.unit }}
          {{ ingredient.ingredient }}
        </li>
      </ul>
      <h3>Tools:</h3>
      <ul>
        <li v-for="(tool, index) in recipeData.tools" :key="index">
          {{ tool.toolName }}
          <span v-if="tool.toolSubstitutes.length > 0">
            (Substitutes: {{ tool.toolSubstitutes.join(', ') }})
          </span>
        </li>
      </ul>
      <h3>Directions:</h3>
      <ol>
        <li v-for="(direction, index) in recipeData.directions" :key="index">
          {{ direction.direction }}
        </li>
      </ol>
      <h3>Nutrition:</h3>
      <p>Calories per serving: {{ recipeData.nutrition.caloriesPerServing }}</p>
      <p>Serving size: {{ recipeData.nutrition.servingSize }}</p>
      <p>Protein per serving: {{ recipeData.nutrition.proteinPerServing }}</p>
      <p>Carbs per serving: {{ recipeData.nutrition.carbsPerServing }}</p>
      <p>Fat per serving: {{ recipeData.nutrition.fatPerServing }}</p>
      <h3>Additional Information:</h3>
      <p>Description: {{ recipeData.additionalData.description }}</p>
      <p>Cuisine type: {{ recipeData.additionalData.cusineType }}</p>
      <p>
        Level of difficulty: {{ recipeData.additionalData.levelOfDifficulty }}
      </p>
      <h3>User Generated Content:</h3>
      <p>
        User rating: {{ recipeData.userGeneratedContent.recipeUserRating }}/5
      </p>
      <p>Suggested alterations:</p>
      <ul>
        <li
          v-for="(alteration, index) in recipeData.userGeneratedContent
            .suggestedAlterations"
          :key="index"
        >
          {{ alteration }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      ingredients: ['', ''],
      calorieLimit: 50,
      recipeData: null,
      loading: false
    }
  },
  methods: {
    addIngredient () {
      this.ingredients.push('')
    },
    async generateRecipe () {
      this.recipeData = null // Clear the current recipe while loading
      const prompt = `Please provide a recipe that incorporates the listed ingredients and stays within the calorie limit.

                        Ingredients:
                        ${this.ingredients.join(', ')}

                        Calorie limit: ${this.calorieLimit} kcal

                        Do not include any explanations, only provide a  RFC8259 compliant JSON response  following this format without deviation.
                        { recipeTitle: "Title of the Recipe", recipeSource: "Source of where the recipe was sourced", ingredients: [ { ingredient: "name of the ingredient", ingredientId: "slugified name of the ingredient", amount: "amount of ingredient", unit: "unit of ingredient", }, ], tools: [{ toolName: "Name of tool/device/instrumet/etc", toolId: "slugified id of the toolName", toolSubstitutes: [ "optional substitute for this tool should the chef not have access to the recommended tool", ] }], directions: [ { direction: "description of the step in directions", ingredientId: "slugified name of the associated ingredient from the ingredients array used in the step of the direction", ingredientId: "slugified name of the associated tool from the to array used in the step of the direction" }, ], nutrition: { caloriesPerServing: "calories per serving in kcal", servingSize: "size of each serving", proteinPerServing: "grams of protein per serving", carbsPerServing: "grams of carbohydrates per serving", fatPerServing: "grams of fat per serving", }, additionalData: { description: "description of the recipe", cusineType: "type of cuisine", levelOfDifficulty:"recipe's level of difficulty", }, userGeneratedContent: { recipeUserRating: "overall rating of the recipe based on all user ratings", suggestedAlterations: ["description of possible alterations to the recipe and the reasoning behind it"] } }
                        `
      this.loading = true

      // Replace this with your OpenAI API call
      const baseUrl = 'https://api.my-recipe-generator.com'
      try {
        const response = await fetch(`${baseUrl}/api/completion`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ prompt })
        })

        if (response.ok) {
          this.loading = false
          this.recipeData = await response.json()
        } else {
          throw new Error('An error occurred while fetching completion.')
        }
      } catch (error) {
        this.loading = false
        console.error(error)
        this.recipeData.recipeTitle =
          'An error occurred while fetching completion.'
      }
    }
  }
}
</script>

<style>
/* Form styling */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 30px auto;
}

/* Input styling */
input {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

/* Add button styling */
/* .add-button {
background-color: #1e88e5;
border: none;
color: white;
text-align: center;
display: inline-block;
font-size: 14px;
width: 25px;
height: 25px;
border-radius: 50%;
cursor: pointer;
margin-left: 5px;
} */

/* Submit button styling */
button {
  background-color: #ff9f43;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #ff7f11;
}

/* Ingredient input styling */
.ingredient-input {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.ingredient {
  flex-grow: 1;
  width: 75%;
}

#calorie-limit {
  width: 31%;
  height: 55px;
  font-size: 33px;
  text-align: center;
}

/* Label styling */
.ingredient-label,
.calorie-limit-label {
  font-size: 18px;
  font-weight: bold;
}

/* Loading container styling */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid #cccccc;
  border-top-color: #1e88e5;
  animation: spin 1s linear infinite;
}

/* Recipe output container */
.recipe-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 30px auto;
  word-wrap: break-word;
}

/* Media query for mobile */
@media (max-width: 768px) {
  form,
  .recipe-container {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
