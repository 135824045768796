<template>
  <div>
    <sign-in-form v-if="!loggedIn" @sign-in="signIn" />
    <recipe-generator-form v-if="loggedIn" />
  </div>
</template>

<script>
import SignInForm from '@/components/SignInForm.vue'
import RecipeGeneratorForm from '@/components/RecipeGeneratorForm.vue'
import { ref } from 'vue'
import { Auth } from 'aws-amplify'

export default {
  components: {
    SignInForm,
    RecipeGeneratorForm
  },
  setup () {
    const loggedIn = ref(false)

    const signUp = async (username, password, email) => {
      try {
        await Auth.signUp({
          username,
          password,
          attributes: { email }
        })
        console.log('Signed up successfully')
      } catch (error) {
        console.error('Error signing up:', error)
      }
    }

    const signIn = async (username, password) => {
      try {
        await Auth.signIn(username, password)
        loggedIn.value = true
      } catch (error) {
        console.error('Error signing in:', error)
      }
    }

    return { loggedIn, signUp, signIn }
  }
}
</script>

<style>
/* General styling */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #e8e0f0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
