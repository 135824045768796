<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style>
/* General styling */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #E8E0F0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
