<template>
  <div class="formContainer">
    <form @submit.prevent="submitForm">
      <input type="text" v-model="username" placeholder="Username" />
      <input type="password" v-model="password" placeholder="Password" />
      <button type="submit">Sign in</button>
    </form>
    <span class="loginCTA"
      >Don't have an account?
      <router-link to="/signup">Sign up here!</router-link></span
    >
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ['sign-in'],
  setup (_, { emit }) {
    const username = ref('')
    const password = ref('')

    const submitForm = () => {
      emit('sign-in', username.value, password.value)
    }

    return { username, password, submitForm }
  }
}
</script>
<style>
span.loginCTA {
  display: block;
  text-align: center;
}
</style>
