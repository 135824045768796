<template>
    <form @submit.prevent="submitForm">
      <input type="text" v-model="username" placeholder="Username" />
      <input type="password" v-model="password" placeholder="Password" />
      <input type="email" v-model="email" placeholder="Email" />
      <button type="submit">Sign up</button>
    </form>
    <span class="loginCTA">Already have an account? <router-link to="/">Log in here!</router-link></span>
  </template>
  
  <script>
  import { ref } from "vue";
  
  export default {
    emits: ["sign-up"],
    setup(_, { emit }) {
      const username = ref("");
      const password = ref("");
      const email = ref("");
  
      const submitForm = () => {
        emit("sign-up", username.value, password.value, email.value);
      };
  
      return { username, password, email, submitForm };
    },
  };
  </script>
  